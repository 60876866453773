import React, { useState, useEffect } from 'react';

const StickyContainer = ({ children }) => {
    const [top, setTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setTop(scrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{ position: 'sticky', top: `${top}px` }}>
            {children}
        </div>
    );
};

export default StickyContainer;
