import React, {useState, useEffect, useContext} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {BrowserRouter as Router, Route, Routes, Outlet, useLocation, matchPath} from 'react-router-dom';

import QRCode from 'qrcode.react';

import Cookies from 'js-cookie';

import {
    selectKioskUrl,
    selectImageBucket,
    setKioskWelcomeMessage
} from "@/app/reducers/kioskSlice.js";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import ModeButton from "@/app/components/ModeButton/ModeButton.jsx";

import Shifts from './routes/Shifts';
import Volunteers from './routes/Volunteers';

import Clock from "@/app/Utilities/Clock/Clock.jsx";
import {getSettings} from "@/app/API/KioskApi.js";
import { AuthContext } from "@/app/context/AuthContext.jsx";

const Root = (props) => {
    const [kioskHostName, setKioskHostName] = useState(props.props.kioskHostName);

    const imageBucket = useSelector(selectImageBucket);

    const [imageFailed, setImageFailed] = useState(false);
    const [headerImage, setHeaderImage] = useState(imageBucket + "/" + props.props.kioskDomainId + "/images/banner-large.jpg");

    const [kioskDomain, setKioskDomain] = useState(props.props.kioskDomainId);

    const location = useLocation();

    const path = "/volunteers/*";
    const hideHeader = matchPath(
        {
            path: path,
            exact: false,
            strict: false
        },
        location.pathname,
    );

    return (
        <div className="container-xxl">
            <div className="utility-top">
                <div className="top-bar row pt-2 pb-2">
                    <div className={"col-md-12"}>
                        <section className={"pt-0"}>
                            <div className={"row"}>
                                <div className="col-md-3 text text-center-sm">
                                    <Clock></Clock>
                                </div>
                                <div className="col-md-6 text text-center">
                                    {kioskHostName}
                                </div>
                                <div className="col-md-3">
                                    {/*<ModeButton></ModeButton>*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="main">
                <div className="row justify-content-center header-section">
                    {/*<div className={"qr-code"}>*/}
                    {/*    <QRCode value={QRLink}/>*/}
                    {/*    <a className={"btn btn-link"} href={QRLink}>*/}
                    {/*        {QRLink}*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                    {!hideHeader && (

                        <div className="col-md-12 p-0 header-image">

                            {imageFailed ? (
                                <img src="https://orion.galaxydigital.com/assets/images/banners/sustain-large.jpg" alt="Welcome to Galaxy Digital"/>

                            ) : (
                                <img src={headerImage} alt="Welcome to Galaxy Digital" onError={() => setImageFailed(true)}/>
                            )}
                        </div>
                    )}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <section className={"content"}>
                            <Outlet/>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

const KioskApp = (props) => {
    const kioskUrl = useSelector(selectKioskUrl);
    const dispatch = useDispatch();

    const [apiKeyLoaded, setApiKeyLoaded] = useState(false);

    const { handleRefreshToken } = useContext(AuthContext);

    useEffect(() => {
        const initialize = async () => {
            try {

                dispatch(setKioskWelcomeMessage(props.welcomeMessage));

                await handleRefreshToken();

                await getSettings();

                await setApiKeyLoaded(true);

            } catch (error) {
                console.error('Error during initialization:', error);
            }
        };

        initialize();

    }, []);

    const kioskHash = Cookies.get('kioskHash');

    const QRLink = kioskUrl + "/launch?userLaunch=" + kioskHash;
    return (
        <>
            <Router>
                {apiKeyLoaded && (
                    <>
                        <Routes>
                            <Route element={<Root props={props}/>}>
                                <Route path="*" element={<Shifts/>}/> {/* Default route */}
                                <Route path="/shifts" element={<Shifts/>}/>
                                <Route path="/volunteers/:shift_id" element={<Volunteers/>}/>
                            </Route>
                        </Routes>
                    </>
                )}
            </Router>
        </>
    );
}


export default KioskApp;
