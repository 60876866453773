import ShiftList from "@/app/components/ShiftList/ShiftList.jsx";
import WelcomeMessage from "@/app/components/WelcomeMessage/WelcomeMessage.jsx";

import React from 'react';
import {useSelector} from 'react-redux';
import {selectKioskWelcomeMessage} from "@/app/reducers/kioskSlice.js";

const Shifts = () => {
    const welcomeMessage = useSelector(selectKioskWelcomeMessage);
    return (
        <>
            <WelcomeMessage message={welcomeMessage}></WelcomeMessage>
            <ShiftList></ShiftList>
        </>
    )
}

export default Shifts;
