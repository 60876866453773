import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import Cookies from 'js-cookie';

import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {selectShifts, shiftsReceived} from "@/app/reducers/shiftSlice.js";

import './ShiftList.css';

import KioskSearch from "@/app/components/KioskSearch/KioskSearch.jsx";
import {useNavigate} from "react-router-dom";
import ShiftItem from "@/app/components/ShiftItem/ShiftItem.jsx";
import {getShifts} from "@/app/API/KioskApi.js";
import {__, lang} from "@/app/Utilities/helpers.js";
import {ClipLoader} from "react-spinners";

const   ShiftList = () => {

    const apiUrl = useSelector(selectApiUrl);
    const shifts = useSelector(selectShifts);

    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState('');

    // this effect will persist the search term in local storage
    // useEffect(() => {
    //     const savedSearchTerm = localStorage.getItem('shiftSearchTerm');
    //
    //     if (savedSearchTerm) {
    //         setSearchTerm(savedSearchTerm);
    //     }
    // }, []);

    const handleSearch = (value) => {
        // Handle input change here
        setSearchTerm(value);
        // localStorage.setItem('shiftSearchTerm', value);
    };
    const filteredShifts = shifts.filter(shift =>
        shift.need.need_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        shift.agency.agency_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filteredShifts.sort((a, b) => a.sort_index.localeCompare(b.sort_index));

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getShifts();

                await setIsLoaded(true);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchData();
    }, []);

    const navigate = useNavigate();

    const handleShiftClick = (shift_id) => {
        navigate(`/volunteers/${shift_id}`);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                        <div className={"kiosk-search-fixed"}>
                            <KioskSearch
                                placeHolder={__("Search by %s or %s", [lang('need'), lang('agency')])}
                                onSearch={handleSearch}
                                initialValue={searchTerm}
                            ></KioskSearch>
                        </div>

                    <div className={"mt-2 mb-1"}>
                        {(searchTerm && filteredShifts.length > 0) && (
                            <div className="text-center">
                                Showing results for "{searchTerm}"...
                            </div>
                        )}
                    </div>

                    <div className="pt-3">
                            {(isLoaded == false && filteredShifts.length === 0) && (
                                <div className="alert text-center" role="alert">
                                    <ClipLoader/>
                                </div>
                            )}

                            {(isLoaded && filteredShifts.length === 0) && (
                                <div className="alert text-center" role="alert">
                                    We couldn’t find any matching shifts! Please try again.
                                </div>
                            )}
                            {filteredShifts.map((shift) => (
                                <div key={shift.sch_id} className="card shift-card" onClick={() => handleShiftClick(shift.sch_id)}>
                                    <ShiftItem shift={shift}></ShiftItem>
                                </div>
                            ))}
                        </div>

                </div>
            </div>
        </>
    )
}

export default ShiftList;
