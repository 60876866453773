import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';

import './AuthLogin.css'
import axios from "axios";

const AuthLogin = ({handleResult}) => {

    const { Formik } = formik;

    const schema = yup.object().shape({
        email: yup.string().required().email().label('Email'),
        password: yup.string().required().label('Password'),
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loginFeedback, setLoginFeedback] = useState('');

    const handleSubmit = async (values) => {
        try {

            setSubmitDisabled(true);

            const postdata = {
                email: values.email,
                password: values.password
            };

            const response = await axios.post('/ajax_post_creds/', postdata);

            if(response.data.success){
                handleResult(response);
            }else{
                setLoginFeedback("Unable to log in");
            }

        } catch (error) {
            console.error('Error in handleSubmit:', error);
            if (error.response && error.response.data.message === 'CSRF token mismatch.') {
                window.location.reload();
            }
        }
    }



    return (
        <>
            <Formik
                validationSchema={schema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                initialValues={{
                    email: (''),
                    password: ('')
                }}
            >
                {({handleSubmit, handleChange, handleBlur, values, touched, errors}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-4">
                            <Form.Group controlId="validationFormik01">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    placeholder={"Email"}
                                    autoComplete='off'
                                    onChange={(e) => {
                                        handleChange(e);
                                        setLoginFeedback('');
                                        setSubmitDisabled(false);
                                    }}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                {/*<Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>*/}
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group controlId="validationFormik02">
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={"Password"}
                                    autoComplete='off'
                                    value={values.firstName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setLoginFeedback('');
                                        setSubmitDisabled(false);
                                    }}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={touched.password && !!errors.password}
                                />
                                {/*<Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>*/}
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        {loginFeedback && (
                            <>
                                <div className={"mb-3 alert-text"}>
                                    {loginFeedback}
                                </div>
                            </>
                        )}

                        <Row className="mb-4">
                            <Form.Group>
                                <Button
                                    disabled={submitDisabled}
                                    className={"text-center password-button"}
                                    type="submit" >
                                        Log in
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AuthLogin;
