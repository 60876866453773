import React, {useContext, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {addResponseToShift} from "@/app/reducers/responseSlice.js";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import SigninForm from "@/app/components/Signup/SigninForm.jsx";

import './Signup.css';
import axios from "axios";

const Signup = ({shift, callClose}) => {

    const apiUrl = useSelector(selectApiUrl);
    const dispatch = useDispatch();

    const [currentComponent, setCurrentComponent] = useState('signin');

    const { checkToken } = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    const volunteer = modalContext.volunteer;
    const team = modalContext.team;

    const createResponse = async (form) => {

        const token = await checkToken();

        if(token == false){
            console.log("No token found");
            return false;
        }

        const postdata = {
            email: volunteer.user_email,
            shift: shift
        }
        const config = {
            headers: {'Authorization': `Bearer ${token}`}
        }
        const signupResult = await axios.post(apiUrl + '/respond',
            postdata,
            config
        );

        let response = signupResult.data.data;

        dispatch(addResponseToShift({
            response: response
        }));

        if(response.checkedIn == false){

            modalContext.updateResponse(response);
            modalContext.updateModalComponent("CheckinForm");

        }else{
            callClose();
        }
    }

    return (
        <>
            <div className={"sign-in-form"}>

                <div className="text-center text-bold mb-4">
                    Sign up
                    {team.team_name && <span> for Team: {team.team_name}</span>}
                </div>

                <SigninForm
                    shift={shift}
                    callClose={callClose}
                ></SigninForm>
            </div>
        </>
    )
}

export default Signup;
