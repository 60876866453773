import React, {useState, useEffect, useContext} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import Cookies from 'js-cookie';

import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {selectResponses, responsesReceived, clearAllResponsesCheckedOut} from "@/app/reducers/responseSlice.js";

import './ResponseList.css';

import KioskSearch from "@/app/components/KioskSearch/KioskSearch.jsx";
import ResponseItem from "@/app/components/ResponseItem/ResponseItem.jsx";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import {__, lang, shiftString} from "@/app/Utilities/helpers.js";
import {useNavigate} from "react-router-dom";
import {ClipLoader} from "react-spinners";

const ResponseList = ({shift, responseAction, disableSignup = false}) => {

    const { checkToken } = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    const apiUrl = useSelector(selectApiUrl);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const filteredResponses = useSelector(state => {
        const responses = selectResponses(state);

        return responses.filter(response => {

            if(response.user === null) return false;

            if(response.team){
                if(response.team.team_name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return true;
                }
            }

            return response.user.user_fname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                response.user.user_lname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                response.user.user_email.toLowerCase().includes(searchTerm.toLowerCase())
        });
    });

    filteredResponses.sort((a, b) => a.user.user_fname.localeCompare(b.user.user_fname));

    const handleSignup = () => {
        responseAction("Signup")
    }
    const handleCheckin = (response) => {
        modalContext.updateResponse(response);
        responseAction("CheckinForm");
    }

    const handleTeamCheckin = (response) => {
        modalContext.updateResponse(response);
        modalContext.updateTeam(response.team);
        modalContext.updateRootResponse(response);

        responseAction("CheckinTeamForm");
    }

    const handleCheckout = (response) => {
        modalContext.updateResponse(response);
        responseAction("CheckoutForm");
    }

    const handleTeamCheckout = (response) => {
        modalContext.updateResponse(response);
        modalContext.updateRootResponse(response);

        responseAction("CheckoutTeamForm");
    }

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await checkToken();

                if(token == false){
                    console.log("No token found");
                    return false;
                }

                const response = await axios.get(apiUrl + '/responses/' + shift.sch_id, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const responses = response.data.data;

                setIsLoaded(true);

                dispatch(responsesReceived(responses));

            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchData();

        const modalData = {
            'modalTitle': shift.need.need_title,
            'modalSubTitle': [shift.agency.agency_name, shiftString(shift)],
            'modalFeedback': ''
        }
        modalContext.updateModalData(modalData);
    }, []);

    return (
        <>
            <div className={"kiosk-search-fixed"}>
                <KioskSearch
                    placeHolder={__("Search by name, email, or %s", [lang('team')])}
                    onSearch={handleSearch}
                ></KioskSearch>
            </div>

            <div className={"mt-2 mb-1"}>
                {searchTerm && (
                    <div className="text-center">
                        Showing results for "{searchTerm}"...
                    </div>
                )}
            </div>

            <div className="pt-4">

                {(isLoaded == false) && (
                    <div className="alert text-center" role="alert">
                        <ClipLoader/>
                    </div>
                )}

                {(isLoaded && filteredResponses.length === 0) && (
                    <div className="alert text-center" role="alert">
                        {disableSignup == true ? (
                            <>
                                We couldn’t find any matching volunteers! Please try again.
                            </>
                        ) : (
                            <>
                                We couldn’t find any matching volunteers! Please try again, or <a href={"#"} className={""} onClick={handleSignup}>sign up</a>
                            </>
                        )}
                    </div>
                )}

                {isLoaded && (
                    filteredResponses.map((response) => (
                        <div key={response.id} className="card response-card">
                            <ResponseItem
                                response={response}
                                checkin={handleCheckin}
                                checkinTeam={handleTeamCheckin}
                                checkout={handleCheckout}
                                checkoutTeam={handleTeamCheckout}
                            ></ResponseItem>
                        </div>
                )))}
            </div>
        </>
    )
}
export default ResponseList;
