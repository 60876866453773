import React, {useContext} from "react";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import Avatar from "@/app/components/Avatar/Avatar.jsx";
import { maskEmail, maskName } from "@/app/Utilities/helpers.js";

import FunctionButton from "@/app/Utilities/FunctionButton/FunctionButton.jsx";
import axios from "axios";

import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {addResponseToShift, updateTeamResponses} from "@/app/reducers/responseSlice.js";
import {useDispatch, useSelector} from "react-redux";

const ConfirmAccount = ({callClose}) => {

    const { checkToken } = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    const volunteer = modalContext.volunteer;
    const shift = modalContext.shift;
    const team = modalContext.team;

    const apiUrl = useSelector(selectApiUrl);
    const dispatch = useDispatch();

    const confirmVolunteer = async () => {
        createResponse();
    }


    // TODO: Move this function to a helper file or to the responseSlice
    const createResponse = async (form) => {

        const token = await checkToken();

        if(token == false){
            console.log("No token found");
            return false;
        }

        const postdata = {
            email: volunteer.user_email,
            shift: shift,
            team: team
        }
        const config = {
            headers: {'Authorization': `Bearer ${token}`}
        }
        const signupResult = await axios.post(apiUrl + '/respond',
            postdata,
            config
        );

        let response = signupResult.data.data;

        dispatch(addResponseToShift({
            response: response
        }));

        if(response.team){
            dispatch(updateTeamResponses({
                response: response
            }));
        }

        if(response.checkedIn == false){

            modalContext.updateResponse(response);

            if(team.id){
                modalContext.updateVolunteer(false);
                modalContext.updateModalComponent("CheckinTeamForm");
            }else{
                modalContext.updateModalComponent("CheckinForm");
            }


        }else{
            callClose();
        }
    }


    const getButtonLabel = () => {
        if(team.id){
            return "Sign up for Team";
        }else{
            return "This is me";
        }
    }
    const buttonLabel = getButtonLabel(team);

    return (
        <>
            <div className="text-center text-bold mb-4">
                Sign up
                {team.team_name && <span> for Team: {team.team_name}</span>}
            </div>

            <div className="row align-items-center">
                <div className="col-2">
                    <Avatar user={volunteer}></Avatar>
                </div>
                <div className="col-10">
                    <div className="text-title">{maskName(volunteer.user_fname, volunteer.user_lname)}</div>
                    <div>{maskEmail(volunteer.user_email)}</div>
                </div>

                <FunctionButton
                    label={buttonLabel}
                    onButtonClick={confirmVolunteer}
                />
            </div>
        </>
    )
}

export default ConfirmAccount;
