import React, {useContext} from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";
import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {addResponseToShift, updateTeamResponses} from "@/app/reducers/responseSlice.js";
import {__, lang} from "@/app/Utilities/helpers.js";

const CreateAccount = ({callClose}) => {

    const { checkToken } = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const volunteer = modalContext.volunteer;
    const shift = modalContext.shift;
    const team = modalContext.team;

    const apiUrl = useSelector(selectApiUrl);
    const dispatch = useDispatch();

    const handleSubmit = (form, { setErrors }) => {
        postSignup(form, setErrors);
    };

    // TODO: Move this function to a helper file or to the responseSlice
    const createResponse = async (form) => {

        const token = await checkToken();

        if(token == false){
            console.log("No token found");
            return false;
        }

        const postdata = {
            email: volunteer.user_email,
            shift: shift,
            team: team
        }
        const config = {
            headers: {'Authorization': `Bearer ${token}`}
        }
        const signupResult = await axios.post(apiUrl + '/respond',
            postdata,
            config
        );

        let response = signupResult.data.data;

        dispatch(addResponseToShift({
            response: response
        }));

        if(response.team){
            dispatch(updateTeamResponses({
                response: response
            }));
        }

        if(response.checkedIn == false){

            if(team.id){
                modalContext.updateVolunteer(false);
                modalContext.updateModalComponent("CheckinTeamForm");
            }else{
                modalContext.updateAutoCheckin(true);
                modalContext.updateResponse(response);
                modalContext.updateModalComponent("CheckinForm");
            }

        }else{
            callClose();
        }
    }

    const postSignup = async (form) => {
        // setDisabled(true);

        try {
            const token = await checkToken();

            if(token == false){
                console.log("No token found");
                return false;
            }

            const postdata = {
                form: form,
                shift: shift
            }
            const config = {
                headers: {'Authorization': `Bearer ${token}`}
            }
            const signupResult = await axios.post(apiUrl + '/signup',
                postdata,
                config
            );

            if(signupResult.data.errors){
                let volunteer = signupResult.data.data;

                modalContext.updateVolunteer(volunteer);
                modalContext.updateModalComponent("Signup.ConfirmAccount");

            }else{
                let response = signupResult.data.data;

                createResponse();
            }

        } catch (error) {
            console.error('Error during sign in:', error);
        }
    }

    const { Formik } = formik;

    const schema = yup.object().shape({
        firstName: yup.string().required().label('First Name'),
        lastName: yup.string().required().label('Last Name'),
        email: yup.string().required().email().label('Email'),
    });

    const signupButtonText = (team.id ? __("Sign up for %s", [lang('Team')]) : "Sign up & Check in");

    return (
        <>
            <div className="text-center text-bold mb-4">
                Sign up
                {team.team_name && <span> for Team: {team.team_name}</span>}
            </div>

            <hr/>

            <Formik
                validationSchema={schema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                initialValues={{
                    email: (volunteer ? volunteer.user_email : ''),
                    firstName: (volunteer ? volunteer.user_fname : ''),
                    lastName: (volunteer ? volunteer.user_lname : ''),
                }}
            >
                {({handleSubmit, handleChange, handleBlur, values, touched, errors}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-4">
                            <Form.Group controlId="validationFormik01">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    placeholder={"Email"}
                                    autoComplete='off'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                {/*<Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>*/}
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email address
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group controlId="validationFormik02">
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder={"First name"}
                                    autoComplete='off'
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.firstName && !errors.firstName}
                                    isInvalid={touched.firstName && !!errors.firstName}
                                />
                                {/*<Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>*/}
                                <Form.Control.Feedback type="invalid">
                                    First Name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group controlId="validationFormik03">
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder={"Last name"}
                                    autoComplete='off'
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.lastName && !errors.lastName}
                                    isInvalid={touched.lastName && !!errors.lastName}
                                />
                                {/*<Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>*/}
                                <Form.Control.Feedback type="invalid">
                                    Last Name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Button className={"text-center"} type="submit">{signupButtonText}</Button>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CreateAccount;
