import {useEffect, useState} from "react";
const Clock = () => {
    const [time, setTime] = useState(new Date().toLocaleTimeString());
    const [timeZone, setTimeZone] = useState("");

    useEffect(() => {

        const interval = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {time}
        </>
    )
}

export default Clock
