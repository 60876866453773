import './Avatar.css';
import {useState} from "react";
import {selectImageBucket} from "@/app/reducers/kioskSlice.js";
import {useSelector} from "react-redux";
const Avatar = ({user}) => {

    const imageBucket = useSelector(selectImageBucket);
    const [imageFailed, setImageFailed] = useState(false);

    const getInitials = (name) => {
        let initials = name.split(' ').map(word => word[0]).join('');
        return initials.toUpperCase();
    }

    const hashString = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash += str.charCodeAt(i);
        }
        return hash;
    }

    const stringToColor = (userEmail) => {
        const hash = hashString(userEmail);
        const color = '#' + ((hash * 1234567890) % Math.pow(2, 24)).toString(16).padStart(6, '0');
        return color;
    }

    // Function to get the current date in YYYY-MM-DD format
    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    if (imageFailed) {
        return (
            <div
                className="avatar avatar-no-image"
                style={{backgroundColor: stringToColor(user.user_email)}}
            >
                {getInitials(user.user_fname + ' ' + user.user_lname)}
            </div>
        );
    } else {
        return (
            <img
                className="avatar"
                src={`${imageBucket}/${user.domain_id}/profile/${user.id}.jpg?v=${getCurrentDate()}`}
                alt="Avatar"
                onError={() => setImageFailed(true)}
            />
        );
    }
}

export default Avatar;
