import React, { createContext, useState, useEffect } from 'react';
import { getValidToken, refreshToken } from '../API/KioskApi.js';
import {now} from "lodash";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const auth = await getValidToken();
            setAuthenticated(auth !== false);
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                startTokenMonitor();
                stopIdleMonitor();
            } else {
                stopTokenMonitor();
                startIdleMonitor();
            }

            // console.log("Visiblity change: ", document.visibilityState, getCurrentDateTime());
        };

        const getCurrentDateTime = () => {
            const timestamp = now();
            const date = new Date(timestamp);
            return date.toISOString(); // or any other desired format
        };

        const startTokenMonitor = () => {
            checkAuth();
            tokenMonitorInterval = setInterval(checkAuth, 5 * 60 * 1000); // Check every 5 minutes
        };

        const stopTokenMonitor = () => {
            clearInterval(tokenMonitorInterval);
        };

        const startIdleMonitor = () => {
            idleMonitorInterval = setInterval(logoutUser, 10 * 60 * 1000); // Log out after 10 minutes of inactivity
        };

        const stopIdleMonitor = () => {
            clearInterval(idleMonitorInterval);
        };

        const logoutUser = () => {
            setAuthenticated(false);
            // Additional logout logic if needed
        };

        let tokenMonitorInterval;
        let idleMonitorInterval;
        startTokenMonitor();

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            stopTokenMonitor();
            stopIdleMonitor();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };

    }, []);

    const handleRefreshToken = async () => {
        if (isRefreshing) return;
        setIsRefreshing(true);
        const result = await refreshToken();
        setAuthenticated(result);
        setIsRefreshing(false);
    };

    const checkToken = async () => {
        const token = await getValidToken();

        if(token == false){
            handleRefreshToken();
        }

        return token;
    };

    return (
        <AuthContext.Provider value={{ authenticated, handleRefreshToken, checkToken }}>
            {children}
        </AuthContext.Provider>
    );
};
