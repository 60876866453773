import { configureStore } from '@reduxjs/toolkit'

import kioskReducer from './reducers/kioskSlice.js'
import shiftReducer from './reducers/shiftSlice.js'
import responseReducer from './reducers/responseSlice.js'

const store = configureStore({
    reducer: {
        kiosk: kioskReducer,
        shift: shiftReducer,
        response: responseReducer
    }
});

export default store;

