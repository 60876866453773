import React, {useState, createContext, useContext, useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectShiftById} from "@/app/reducers/shiftSlice.js";
import {responsesReceived, selectResponses} from "@/app/reducers/responseSlice.js";

import {__, checkFull, getResponseCount, lang, shiftString} from "@/app/Utilities/helpers.js";

import { FaLock } from "react-icons/fa";

import ShiftItem from "@/app/components/ShiftItem/ShiftItem.jsx";
import ResponseList from "@/app/components/ResponseList/ResponseList.jsx";

// Modal Components
import ModalGroup from "@/app/Utilities/ModalGroup/ModalGroup.jsx";

import Signup from "@/app/components/Signup/Signup.jsx";
import SigninForm from "@/app/components/Signup/SigninForm.jsx";
import ConfirmAccount from "@/app/components/Signup/ConfirmAccount.jsx";
import CreateAccount from "@/app/components/Signup/CreateAccount.jsx";

import CheckinForm from "@/app/components/CheckinForm/CheckinForm.jsx";
import CheckinTeamForm from "@/app/components/CheckinTeamForm/CheckinTeamForm.jsx";
import CheckoutForm from "@/app/components/CheckoutForm/CheckoutForm.jsx";
import CheckoutTeamForm from "@/app/components/CheckoutTeamForm/CheckoutTeamForm.jsx";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContextProvider} from "@/app/context/ModalContext.jsx";

import axios from "axios";
import {getShifts} from "@/app/API/KioskApi.js";
import StickyContainer from "@/app/Utilities/StickyContainer/StickyContainer.jsx";

const Volunteers = () => {
    let { shift_id } = useParams();

    const { checkToken } = useContext(AuthContext);

    const currentShift = useSelector(state => selectShiftById(state, shift_id));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchShifts = async () => {
            if (!currentShift) {
                await getShifts();
            }
        };

        fetchShifts();
    }, [currentShift]);

    const [modalGroupIsOpen, setModalGroupIsOpen] = useState(false);

    const closeModalGroup = (resetToHome = false) => {
        setRootComponent("");
        setModalGroupIsOpen(false);

        if(resetToHome){
            navigate(`/`);
        }
    }

    const [rootComponent, setRootComponent] = useState("");

    const handleOpenModalGroup = (componentKey) => {
        setRootComponent(componentKey);
        setModalGroupIsOpen(true);
    }

    const handleResponseAction = (componentKey) => {
        handleOpenModalGroup(componentKey);
    }

    const handleSignupClick = (response) => {

        handleOpenModalGroup("Signup");
    }

    const handleBackButtonState = (state) => {
        setShowBackButton(state);
    }

    const handleResetDone = () => {
        setResetCheckinModal(false);
    };

    const handleBack = () => {

        // reset responses
        dispatch(responsesReceived([]));

        navigate(`/shifts`);
    }

    const responses = useSelector(selectResponses); // Watch responses state
    const [responseCount, setResponseCount] = useState(0);

    useEffect(() => {

        setResponseCount(getResponseCount(responses));

    }, [responses]);

    if(!currentShift){
        return <div>Loading...</div>;
    }

    const isIntegerOrEmptyString = (value) => {
        if (Number.isInteger(value)) {
            return true;
        }
        if (typeof value === 'string' && !isNaN(parseInt(value, 10))) {
            return true;
        }
        if(typeof value === 'string' && value === ''){
            return true;
        }
        return false;
    };

    const createResponse = async (form) => {

        const token = await checkToken();

        if(token == false){
            console.log("No token found");
            return false;
        }

        const postdata = {
            email: volunteer.user_email,
            shift: shift
        }
        const config = {
            headers: {'Authorization': `Bearer ${token}`}
        }
        const signupResult = await axios.post(apiUrl + '/respond',
            postdata,
            config
        );

        let response = signupResult.data.data;

        dispatch(addResponseToShift({
            response: response
        }));

        if(response.checkedIn == false){

            modalContext.updateResponse(response);
            modalContext.updateModalComponent("CheckinForm");

        }else{
            callClose();
        }
    }
    const modalGroupComponents = [
        { key: "Signup", component: <Signup shift={currentShift} callClose={closeModalGroup} /> },
        { key: "Signup.SigninForm", component: <SigninForm shift={currentShift} callClose={closeModalGroup} /> },
        { key: "Signup.ConfirmAccount", component: <ConfirmAccount shift={currentShift} callClose={closeModalGroup} /> },
        { key: "Signup.CreateAccount", component: <CreateAccount shift={currentShift} callClose={closeModalGroup} /> },

        { key: "CheckinForm", component: <CheckinForm callClose={closeModalGroup} /> },
        { key: "CheckinTeamForm", component: <CheckinTeamForm callClose={closeModalGroup} /> },
        { key: "CheckoutForm", component: <CheckoutForm callClose={closeModalGroup} /> },
        { key: "CheckoutTeamForm", component: <CheckoutTeamForm callClose={closeModalGroup} /> }
    ];

    const modalData = {
        'modalTitle': currentShift.need.need_title,
        'modalSubTitle': [currentShift.agency.agency_name, shiftString(currentShift)],
        'modalFeedback': ""
    }

    const isPrivate = () => {
        return currentShift.need.need_public == false;
    }

    const noGroups = () => {
        return currentShift.need.need_groups.length == 0;
    }

    const isLocked = () => {
        return isPrivate() && noGroups();
    }

    return (
        <>
            <div className="volunteer-view">
                <div id="volunteer-header-fixed" className={"volunteer-header-fixed"}>
                    <div className="row pb-4">
                        <div className="col-6">
                            <a className="btn btn-light border btn-sm float-sm-start" onClick={handleBack}>Go Back</a>
                        </div>

                        <div className="col-6">
                            {!checkFull(currentShift.slots, responseCount) ?
                                (<button
                                    disabled={isLocked()}
                                    id={"signup-button"}
                                    className={`btn float-end align-middle ${isLocked() ? 'btn-disabled' : 'btn-primary'}`}

                                    onClick={handleSignupClick}>
                                    {isPrivate() && <FaLock />}

                                    {isLocked() ? (
                                        <>Private</>
                                    ) : (
                                        <>Sign Up</>
                                    )}
                                </button>) :
                                (<div className="btn btn-danger btn-sm float-end">FULL</div>)
                            }

                            {isPrivate() && (
                                <>
                                    <div className={"button-message pt-1"}>{__("This %s is private, sign up is restricted", [lang('need')])}</div>
                                </>
                            )}

                        </div>
                    </div>
                    <div className="card shift-card">
                        <ShiftItem
                            shift={currentShift}
                            details={true}
                        ></ShiftItem>
                    </div>
                </div>

                <ModalContextProvider currentShift={currentShift}>

                    <StickyContainer>
                        <div className="row pt-4">
                            <div className="col-md-12">
                                <ResponseList
                                    shift={currentShift}
                                    responseAction={handleResponseAction}
                                    disableSignup={isLocked()}
                                ></ResponseList>
                            </div>
                        </div>
                    </StickyContainer>

                    <ModalGroup
                        shift={currentShift}
                        rootComponent={rootComponent}
                        modalGroupIsOpen={modalGroupIsOpen}
                        modalGroupComponents={modalGroupComponents}
                        closeGroup={closeModalGroup}
                        modalData={modalData}
                    >
                    </ModalGroup>
                </ModalContextProvider>

            </div>
        </>
    )
}

export default Volunteers;
