import React, {useState, useContext} from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Cookies from "js-cookie";
import axios from "axios";

import {__, lang} from "@/app/Utilities/helpers.js";

import {useSelector} from "react-redux";
import {selectApiUrl} from "@/app/reducers/kioskSlice.js";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import {GoAlertFill} from "react-icons/go";
import {selectResponses} from "@/app/reducers/responseSlice.js";
import {getValidToken} from "@/app/API/KioskApi.js";

const SigninForm = () => {
    const apiUrl = useSelector(selectApiUrl);
    const responses = useSelector(selectResponses);

    const [formFeedback, setFormFeedback] = useState('');

    const { checkToken } = useContext(AuthContext);

    const modalContext = useContext(ModalContext);
    const volunteer = modalContext.volunteer;
    const shift = modalContext.shift;
    const team = modalContext.team;

    const [signinFeedback, setSigninFeedback] = useState('');

    const handleSubmit = (form) => {
        postSignin(form);
    };

    const [signinError, setSigninError] = useState('');
    const postSignin = async (form) => {
        setSubmitDisabled(true);

        try {
            const token = await checkToken();

            if(token == false){
                console.log("No token found");
                return false;
            }

            const postdata = {
                form: form,
                shift: shift,
                team: team
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const lookupResult = await axios.post(apiUrl + '/volunteerLookup',
                postdata,
                config
            );

            if(lookupResult.data.errors){

                if(lookupResult.data.message == "Qualifications Rejected"){
                    let feedback = __("This email has one or more rejected, required %s", [lang("qualifications")]);
                    setSigninFeedback(feedback);
                    return;
                }

                if(shift.need.need_public == false){
                    let feedback = __("This email address does not have permission to sign up for this %s", [lang('need')])
                    setSigninFeedback(feedback);
                    return;
                }

                modalContext.updateVolunteer({'user_email': form.email});
                modalContext.updateModalComponent("Signup.CreateAccount");

            }else{
                let volunteer = lookupResult.data.data;

                if(volunteer.user_status == 'active'){

                    if(shift.need.need_public == false){


                        let needGroupIds = getUgIds(shift.need.need_groups);
                        let userGroupIds = getUgIds(volunteer.user_groups);

                        let hasPermission = needGroupIds.some(id => userGroupIds.includes(id));

                        if(!hasPermission){
                            let feedback = __("This email address does not have permission to sign up for this %s", [lang('need')])
                            setSigninFeedback(feedback);
                            return;
                        }
                    }

                    modalContext.updateVolunteer(volunteer);
                    modalContext.updateModalComponent("Signup.ConfirmAccount");
                }else{
                    setSigninError("There was an issue accessing your account. Please reach out to a manager at " + volunteer.domain_sitename);
                }
            }

        } catch (error) {
            console.error('Error during sign in:', error);
        }
    }

    const getUgIds = (groupArray) => {
        if (Array.isArray(groupArray)) {
            return groupArray.map(group => group.ug_id);
        }
        return [];
    };

    const { Formik } = formik;

    const schema = yup.object().shape({
        email: yup.string().required().email(),
        // password: yup.string().required()
    });

    const customValidate = (values) => {
        const errors = {};

        if(Object.keys(team).length > 0){

            let teamResponses = responses.filter(response => response.team_id == team.team_id);

            let dupeTeamMember = teamResponses.find(response => response.user.user_email === values.email);

            if (dupeTeamMember) {

                errors.email = __("This user is already a member of this %s", [lang('team')]);
            }
        }

        return errors;
    };

    const [submitDisabled, setSubmitDisabled] = useState(false);

    return (
        <>
            {signinError ? (
                <div className="alert alert-light signin-alert">
                    <div className={"row"}>
                        <div className={"col-2"}>
                            <span className={"dupe-alert-icon round-border-icon"}>
                                 <GoAlertFill/>
                            </span>
                        </div>
                        <div className={"col-10"}>
                            <div>{signinError}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <Formik
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={customValidate}
                    onSubmit={handleSubmit}
                    initialValues={{
                        email: (volunteer ? volunteer.user_email : ''),
                        // password: ''
                    }}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                <Form.Group controlId="validationFormik01">
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        autoComplete='off'
                                        value={values.email}
                                        placeholder={"Email"}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setSigninFeedback('');
                                            setSubmitDisabled(false);
                                        }}
                                        onBlur={handleBlur}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={touched.email && !!errors.email}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.email || "Please provide a valid email address"}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                {formFeedback && (
                                    <>
                                        {showFeedbackLink()}
                                    </>
                                )}

                                {signinFeedback && (
                                   <>
                                       <div className={"mb-3 alert-text"}>
                                           {signinFeedback}
                                       </div>
                                   </>
                                )}

                                <Button
                                    className={"text-center"}
                                    type="submit"
                                    disabled={submitDisabled}
                                >Sign up</Button>
                            </Row>

                        </Form>
                    )}
                </Formik>
            )}
        </>
    )
}

export default SigninForm;
