import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../kioskStore.js';
import { setDomainSettings, setKioskHash } from '../reducers/kioskSlice.js';
import {shiftsReceived} from "@/app/reducers/shiftSlice.js";
import { selectApiUrl } from '../reducers/kioskSlice.js';
import {getLocaleTimeZone} from "@/app/Utilities/helpers.js";

const apiUrl = selectApiUrl(store.getState());

const locale = 'en';
const languageOverrides = {};

const populateLanguageOverrides = (overrides) => {
    overrides.forEach((override) => {
        if (!languageOverrides[override.lang]) {
            languageOverrides[override.lang] = {};
        }
        languageOverrides[override.lang][override.key] = override.value;
    });

    const languageOverridesJSON = JSON.stringify(languageOverrides[locale]);

    localStorage.setItem('languageOverrides', languageOverridesJSON);
};

export const getSettings = async () => {

    const token = await getValidToken();

    if(token == false){
        console.log("No token found");
        window.location.reload();
        return;
    }

    try {
        const response = await axios.get(apiUrl + '/settings', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.data.data.language_overrides.length > 0) {
            populateLanguageOverrides(response.data.data.language_overrides);
        }

        store.dispatch(setDomainSettings(response.data.data));

        return response.data.data;
    } catch (error) {
        console.log(error);
    }

};

export const refreshToken = async () => {
    try {

        const postdata = {
            local_timezone: getLocaleTimeZone()
        };

        const response = await axios.post('/ajax_get_token/', postdata);

        if(response.data.token == false){
            Cookies.remove('apiToken');
            Cookies.remove('kioskHash');

            return false;
        }

        store.dispatch(setKioskHash(response.data.kioskHash));

        Cookies.set('kioskHash', response.data.kioskHash, { expires: 1, secure: true, sameSite: 'Strict' });
        Cookies.set('apiToken', response.data.token, { expires: 1/12, secure: true, sameSite: 'Strict' });

        return true;

    } catch (error) {
        console.error('Error refreshing token:', error);

        if(Cookies.get('apiToken')){
            return true;
        }else{
            return false;
        }
    }
};

const isTokenExpired = (token) => {
    if (!token || token ===  'undefined') return true;

    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp < Date.now() / 1000;
};

export const getValidToken = async () => {
    let token = Cookies.get('apiToken');

    if (isTokenExpired(token)) {
        let result = await refreshToken();

        if(result === false){
            return false;
        }

        token = Cookies.get('apiToken');
    }

    return token;
};

export const getShifts = async () => {
    try {
        const token = await getValidToken();

        if(token == false){
            window.location.reload();
            return false;
        }

        const response = await axios.get(apiUrl + '/shifts', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const shifts = response.data.data;

        store.dispatch(shiftsReceived(shifts));

    } catch (error) {
        console.error('Error fetching shifts:', error);
    }
}
