import React, {useContext, useEffect, useRef, useState} from 'react';
import {ModalContext} from "@/app/context/ModalContext.jsx";

import Modal from "@/app/Utilities/Modal/Modal.jsx";

const ModalGroup = ({shift, rootComponent, modalGroupIsOpen, modalGroupComponents, closeGroup, modalData}) => {

    const modalContext = useContext(ModalContext);

    const findComponentByKey = (key) => {

        let component = modalGroupComponents.find(item => item.key === key)?.component;

        return component;
    };

    const [componentHistory, setComponentHistory] = useState([]);
    const [groupModalComponent, setGroupModalComponent] = useState(null);

    useEffect(() => {

        if (rootComponent) {
            // console.log("loading root component", rootComponent);

            setComponentHistory([]);

            modalContext.updateModalComponent(rootComponent);
        }
    }, [rootComponent]);

    useEffect(() => {
        // console.log("loading group modal component", modalContext.modalComponent);

        if(!componentHistory.includes(modalContext.modalComponent)){
            setComponentHistory([...componentHistory, modalContext.modalComponent]);
        }

        const component = findComponentByKey(modalContext.modalComponent);

        setGroupModalComponent(component ? React.cloneElement(component, {
            response: modalContext.response,
            responseId: modalContext.response.id,
        }) : null);

    }, [modalContext.modalComponent]);

    // Set initial modal data
    const [modalGroupModalData, setModalGroupModalData] = useState(modalData);

    // Save initial value for reset on modal close
    const initialModalDataRef = useRef(modalData);

    // Listen for changes in modal data
    useEffect(() => {
        // Perform actions when context data changes
        // console.log('Use Effect watching modal data context: ', modalContext.modalData);

        setModalGroupModalData(modalContext.modalData);

            // console.log(modalGroupModalData);

    }, [modalContext.modalData]);

    // Clear component history when modal is closed
    useEffect(() => {
        if(modalGroupIsOpen == false){
            callModalGroupClose();
        }
    }, [modalGroupIsOpen]);


    const callModalGroupClose = () => {

        modalContext.resetSignupComponent();

        modalContext.updateModalComponent("");

        modalContext.updateTeam({});

        modalContext.updateRootResponse(null);

        modalContext.clearAllTimeouts();

        setComponentHistory([]);

        setModalGroupModalData(initialModalDataRef.current);

        let resetToHome = modalContext.resetToHome;
        modalContext.updateResetToHome(false);

        closeGroup(resetToHome);
    }

    const showBackButton = componentHistory.length > 1;

    const handleModalBackButton = () => {
        // console.log("back button clicked");

        if(componentHistory.length > 1){
            // Remove the last item in the history
            const updatedHistory = componentHistory.slice(0, -1);

            // Update the state with the new history
            setComponentHistory(updatedHistory);

            // get the last item in component history
            let previousComponent = updatedHistory[updatedHistory.length - 1];

            modalContext.updateModalComponent(previousComponent);
        }
    }

    return (
        <>
            <Modal
                backButton={{state: showBackButton, backButtonFunction: handleModalBackButton}}
                isOpen={modalGroupIsOpen}
                onClose={callModalGroupClose}
                modalTitle={modalGroupModalData.modalTitle}
                modalSubTitle={modalGroupModalData.modalSubTitle}
                modalFeedback={modalGroupModalData.modalFeedback}
            >
                {groupModalComponent}
            </Modal>
        </>
    )
}

export default ModalGroup;
