import React, {useEffect, useState, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApiUrl, selectSettings} from "@/app/reducers/kioskSlice.js";
import {setResponseCheckedIn, setResponseCheckedOut} from "@/app/reducers/responseSlice.js";
import {selectShifts, shiftsReceived} from "@/app/reducers/shiftSlice.js";

import './CheckinForm.css';

import Cookies from "js-cookie";
import axios from "axios";
import {formatTime, getLocaleTimeZone, maskEmail, maskName} from "@/app/Utilities/helpers.js";

import Avatar from "@/app/components/Avatar/Avatar.jsx";
import SubmitButton from "@/app/Utilities/SubmitButton/SubmitButton.jsx";
import UserGroupSelect from "@/app/components/UserGroupSelect/UserGroupSelect.jsx";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import {DuplicateCheckin} from "@/app/components/DuplicateCheckin/DuplicateCheckin.jsx";

const CheckinForm = ({response, callClose}) => {
    const apiUrl = useSelector(selectApiUrl);
    const shifts = useSelector(selectShifts);

    const dispatch = useDispatch();

    const [buttonLabel, setButtonLabel] = useState("Check in now");
    const [checkedInState, setCheckedInState] = useState(false);

    const [countDown, setCountDown] = useState(0);
    const [userGroups, setUserGroups] = useState([]);

    const { checkToken } = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    const [dupeCheckFinished, setDupeCheckFinished] = useState(false);
    const [dupeCheckin, setDupeCheckin] = useState(false);
    const [otherShiftName, setOtherShiftName] = useState("");
    const [dupeCheckinResponse, setDupeCheckinResponse] = useState(null);

    useEffect(() => {
        // check if auto checkin is enabled
        if(modalContext.autoCheckin){
            setDupeCheckFinished(true);
            setDupeCheckin(false);
            postCheckin(response);
        }else{
            // otherwise check for duplicate checkin
            duplicateCheckin();
        }

    }, []);

    const handleCheckoutDupe = async () => {

        if(dupeCheckinResponse == null){
            setDupeCheckFinished(true);
            setDupeCheckin(false);
            return;
        }

        try {
            const token = await checkToken();

            if(token == false){
                console.log("No token found");
                return false;
            }

            const postdata = {
                response: dupeCheckinResponse,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const checkoutResult = await axios.post(apiUrl + '/checkout',
                postdata,
                config
            );

            dispatch(setResponseCheckedOut({
                id: response.id,
                checkedIn: false,
                checkedOut: true,
                todaysHour: checkoutResult.data.data.todaysHour
            }));

        } catch (error) {
            setDupeCheckFinished(true);
            setDupeCheckin(false);
            console.error('Error fetching options:', error);
        }

        setDupeCheckFinished(true);
        setDupeCheckin(false);
    }

    const duplicateCheckin = async () => {
        const shiftIds = shifts.map(shift => shift.sch_id);

        // if there is only one shift then there is no need to check for duplicate checkin
        if (shiftIds.length <= 1) {
            setDupeCheckFinished(true);
            setDupeCheckin(false);

            return false;
        }

        try {

            const token = await checkToken();

            if(token == false){
                console.log("No token found");
                return false;
            }

            const postdata = {
                userId: response.user.id,
                shiftIds: shiftIds,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const dupeResult = await axios.post(apiUrl + '/checkinDupe',
                postdata,
                config
            );

            if(dupeResult.data){

                const dupeResultResponse = dupeResult.data.data;

                setDupeCheckinResponse(dupeResultResponse);

                setOtherShiftName(dupeResultResponse.need.need_title);

                setDupeCheckFinished(true);
                setDupeCheckin(true);
            }else{
                setDupeCheckFinished(true);
                setDupeCheckin(false);
            }

        } catch (error) {
            console.error('Error fetching options:', error);
            setDupeCheckFinished(true);
            setDupeCheckin(false);
        }
    }

    const [userGroupsSelected, setUserGroupsSelected] = useState([]);
    useEffect(() => {
        setUserGroupsSelected(userGroups.filter(option => option.selected).map(({ id }) => id));

    }, [userGroups]);

    const [isTouched, setIsTouched] = useState(false);
    useEffect(() => {
        if(isTouched){
            validateUserGroups();
        }
    }, [userGroupsSelected, isTouched]);

    const startCountDown = (seconds) => {
        setCountDown(seconds);

        const interval = setInterval(() => {
            setCountDown(countDown => countDown - 1);
        }, 1000);

        const timeout = setTimeout(() => {
            clearInterval(interval);
            callClose();
        }, seconds * 1000);

        modalContext.registerTimeout(timeout);
    }

    const userGroupHourSettings = useSelector(state => selectSettings(state, 'user_group_required'));
    const [userGroupValid, setUserGroupValid] = useState(true);
    const validateUserGroups = () => {

        if(userGroupHourSettings === null){
            return true;
        }

        if(userGroupHourSettings == 2 && userGroups.length > 0 && userGroupsSelected.length == 0){
            setUserGroupValid(false);
            return false;
        }else{
            setUserGroupValid(true);
            return true;
        }
    }

    const postCheckin = async (response) => {

        if(userGroupValid == false){
            return;
        }

        try {
            const token = await checkToken();

            if(token == false){
                console.log("No token found");
                return false;
            }

            const postdata = {
                response: response,
                userGroups: userGroupsSelected,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const checkinResult = await axios.post(apiUrl + '/checkin',
                postdata,
                config
            );

            setCheckedInState(true);
            modalContext.updateAutoCheckin(false);

            dispatch(setResponseCheckedIn({
                id: response.id,
                checkedIn: true,
                checkedOut: false,
                todaysHour: checkinResult.data.data.todaysHour
            }));

            const newModalData = {
                modalTitle: "You checked in at " + formatTime(checkinResult.data.data.todaysHour[0].hour_date_start),
                modalSubtitle: ""
            }

            modalContext.updateModalData(newModalData);
            modalContext.updateResetToHome(true);

            setButtonLabel("Close");
            startCountDown(5);

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const setSelectedUserGroups = (selectedGroups) => {
        setUserGroups(selectedGroups);
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {

        if (isSubmitting){
            return;
        }
        setIsSubmitting(true);

        if(!validateUserGroups()){
            setIsSubmitting(false);
            return;
        }

        if(checkedInState){
            callClose();
            setIsSubmitting(false);
        }else{
            postCheckin(response).finally(() => setIsSubmitting(false));
        }
    }

    return (
        <>
            <div className="row">
                {dupeCheckFinished == false ? (
                    <></>
                ) : (
                    dupeCheckin ? (
                        <DuplicateCheckin
                            onCheckoutDupe={handleCheckoutDupe}
                            shiftName={otherShiftName}
                        />
                    ) : (
                        <>
                            <div className="col-2">
                                <Avatar user={response.user}/>
                            </div>
                            <div className="col-10">
                                <div
                                    className="text-title">{maskName(response.user.user_fname, response.user.user_lname)}</div>
                                <div>{maskEmail(response.user.user_email)}</div>
                            </div>

                            {countDown > 0 && (
                                <div className="pt-3 text-center">This window will close in {countDown} seconds</div>
                            )}

                            <div className="mt-4">
                                {userGroupHourSettings > 0 && (
                                    <UserGroupSelect
                                        userId={response.user.id}
                                        needId={response.need.id}
                                        updateSelected={setSelectedUserGroups}
                                        checkedInState={checkedInState}
                                        isIvalid={userGroupValid}
                                        setIsTouched={setIsTouched}
                                    />
                                )}
                            </div>
                            <SubmitButton
                                label={buttonLabel}
                                onSubmitClick={handleSubmit}
                            />
                        </>
                    )
                )}
            </div>
        </>
    )
}

export default CheckinForm;

