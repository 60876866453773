import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const shiftSlice = createSlice({
    name: 'shifts',
    initialState: {
        loading: 'idle',
        shifts: []
    },
    reducers: {
        shiftsReceived: (state, action) => {
            state.shifts = action.payload
        }
    },
});

export const {
    shiftsReceived
} = shiftSlice.actions;

export const selectShifts = (state) => state.shift.shifts;

export const selectShiftsData = createSelector(
    [selectShifts],
    (shifts) => shifts.data
);

export const selectShiftById = (state, shiftId) => {
    let selectedShift =  state.shift.shifts.find(shift => shift.sch_id == shiftId);
    return selectedShift;
}

export default shiftSlice.reducer;
