import React, {useContext, useEffect, useState} from "react";
import FunctionButton from "@/app/Utilities/FunctionButton/FunctionButton.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";
import { GoAlertFill } from "react-icons/go";

import './DuplicateCheckin.css';
import {truncateString} from "@/app/Utilities/helpers.js";

export const DuplicateCheckin = ({onCheckoutDupe, shiftName=""}) => {

    const modalContext = useContext(ModalContext);
    const currentShift = modalContext.shift;

    const [dupeButtonText, setDupeButtonText] = useState("");
    useEffect(() => {
        setDupeButtonText("Check out and check in to " + currentShift.need.need_title);
    }, []);

    return (
        <>
            <div className="alert alert-light">
                <div className={"row"}>
                    <div className={"col-2"}>
                        <span className={"dupe-alert-icon round-border-icon"}>
                             <GoAlertFill/>
                        </span>
                    </div>
                    <div className={"col-10"}>
                        <div>You're already checked in for</div>
                        <div>{shiftName}</div>
                    </div>
                </div>
            </div>

            <FunctionButton
                label={dupeButtonText}
                onButtonClick={onCheckoutDupe}
                buttonStyle="btn-checked-in"
            />

            {/*<FunctionButton*/}
            {/*    label={"Cancel"}*/}
            {/*    onButtonClick={checkoutAndCheckin}*/}
            {/*    buttonStyle="btn-light border"*/}
            {/*/>*/}
        </>
    )
}
