import './TeamManagementControls.css';
import FunctionButton from "@/app/Utilities/FunctionButton/FunctionButton.jsx";
import React, {useContext, useEffect, useState} from "react";

import Cookies from "js-cookie";
import axios from "axios";

import {useDispatch, useSelector} from "react-redux";

import {AuthContext} from "@/app/context/AuthContext.jsx";
import {ModalContext} from "@/app/context/ModalContext.jsx";

import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {__, lang, getLocaleTimeZone, getResponseCount, checkFull} from "@/app/Utilities/helpers.js";
import {updateResponse, selectResponses} from "@/app/reducers/responseSlice.js";

const TeamManagementControls = ({responseId, team}) => {

    const { checkToken } = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    const apiUrl = useSelector(selectApiUrl);
    const dispatch = useDispatch();

    const responses = useSelector(selectResponses);
    const response = responses.find(resp => resp.id === responseId);
    const [responseCount, setResponseCount] = useState(0);

    useEffect(() => {

        setResponseCount(getResponseCount(responses));

    }, [responses]);

    const addTeamMember = () => {
        modalContext.updateModalComponent("Signup");
    }

    const dropSlot = async () => {

        try {
            const token = await checkToken();

            if(token == false){
                console.log("No token found");
                return false;
            }

            const postdata = {
                responseId: response.id,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: {'Authorization': `Bearer ${token}`}
            }

            const dropResult = await axios.post(apiUrl + '/dropTeamSlot',
                postdata,
                config
            );

            const freshResponse = dropResult.data.data;

            dispatch(updateResponse(freshResponse));

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const reservedSlots = () => {
        let slotsString = "slots"
        if(team.reservedSlots == 1){
            slotsString = "slot";
        }
        return team.reservedSlots + " unfilled " + slotsString;
    }

    return (
        <>
            <div className={"team-manage-wrapper"}>

                <div className="fixed-width">
                    <span>{reservedSlots()}</span>
                </div>

                <div className="flex-grow m-1">
                    <FunctionButton
                        label={__("Add a %s member", [lang('team')])}
                        onButtonClick={addTeamMember}
                        buttonStyle={"btn-outline-secondary p-1 mt-1"}
                        isDisabled={checkFull(modalContext.shift.slots, responseCount)}
                        tooltipText={checkFull(modalContext.shift.slots, responseCount) ? __("This shift is Full") : ""}
                    />
                </div>


                <div className="flex-grow m-1">
                    <FunctionButton
                        label="Drop a spot"
                        onButtonClick={dropSlot}
                        buttonStyle={"btn-outline-secondary p-1 mt-1"}
                        isDisabled={team.reservedSlots < 1}
                    />
                </div>

            </div>
        </>
    )
}
export default TeamManagementControls;
