import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import DOMPurify from 'dompurify';

import {selectKioskUrl} from "@/app/reducers/kioskSlice.js";
import {selectResponses} from "@/app/reducers/responseSlice.js";

import './ShiftItem.css';
import Cookies from "js-cookie";
import {getResponseCount, shiftString} from "@/app/Utilities/helpers.js";

const ShiftItem = ({shift, details}) => {

    const kioskHash = Cookies.get('kioskHash');
    const kioskUrl = useSelector(selectKioskUrl);

    const [showDetails, setShowDetails] = useState(false);
    const QRLink = kioskUrl + "/userShiftLaunch?k=" + kioskHash + "&s=" + shift.sch_id;

    const responses = useSelector(selectResponses);
    const [responseCount, setResponseCount] = useState(0);
    useEffect(() => {
        if(details){

            setResponseCount(getResponseCount(responses));

        }
    }, [details, useSelector(selectResponses)]);

    const responseCountString = () => {
        if(details && shift.slots){

            let slotString =  shift.slots + " slots filled";
            if(shift.slots == 1){
                slotString = "1 slot filled";
            }

            return responseCount + " out of " + slotString;
        }
    }

    return (
        <>
            <div className={"row"}>
                <div className={"col-md-9"}>
                    <h2>{shift.need.need_title}</h2>
                    <div>
                        {shift.agency.agency_name}
                    </div>
                    <div>
                        {shiftString(shift)}
                    </div>
                    <div>{responseCountString()}</div>

                    <div>
                        {/*<a className={"btn btn-link text-small"} href={QRLink}>{QRLink}</a>*/}
                    </div>
                </div>
                {/*<div className={"col-md-3 text-md-end"}>*/}
                {/*    <QRCode value={QRLink} size={75} imageSettings={{height: 50, width: 50}}/>*/}
                {/*</div>*/}
            </div>

            {showDetails && details && (
                <div className="mt-3"
                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(shift.need.need_body, {FORBID_TAGS: ['a']})}}>
                </div>
            )}

            {details && (
                <div className="show-details text-center text-small">
                    <a onClick={() => setShowDetails(!showDetails)}>
                        {showDetails ? 'Hide Details' : 'Show Details'}
                    </a>
                </div>
            )}

        </>
    )
}

export default ShiftItem;
