import {useEffect, useState} from "react";

const WelcomeMessage = ({message}) => {

    return (
        <>
            <div className="row pt-3 pb-3">
                <div className="col-md-12">
                    {message}
                </div>
            </div>
        </>
    )
}

export default WelcomeMessage;
