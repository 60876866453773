import React from 'react';
import './SubmitButton.css';
import {isDisabled} from "bootstrap/js/src/util/index.js";

const SubmitButton = ({label, onSubmitClick, isDisabled= false, buttonClass = 'btn-primary'}) => {
    return (
        <>
            <div className="gcComponent input-submit-button">
                <button
                    className={`btn ${buttonClass}`}
                    onClick={onSubmitClick}
                    disabled={isDisabled}>
                    {label}
                </button>
            </div>
        </>
    )
}

export default SubmitButton;
