import { FaMagnifyingGlass  } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";
import "bootstrap-icons/font/bootstrap-icons.css"

import './KioskSearch.css';
import {useState} from "react";
const KioskSearch = ({onSearch, initialValue = "", placeHolder="Search"}) => {

    const [searchTerm, setSearchTerm] = useState(initialValue);
    const handleInput = (e) => {
        const newValue = e.target.value;

        setSearchTerm(newValue);

        // Call parent component's input change handler
        if (onSearch) {
            onSearch(newValue);
        }
    };

    const clearSearchTerm = () => {
        setSearchTerm("");

        if (onSearch) {
            onSearch("");
        }
    }

    return (
        <>
            <div className="kiosk-search input-group">
                <input
                    className="form-control border-right-0 border"
                    type="search"
                    value={searchTerm}

                    placeholder={placeHolder}
                    onInput={handleInput}
                />

                <span className="input-group-prepend">
                    <div className="btn">
                        <FaMagnifyingGlass/>
                    </div>
                </span>

                {searchTerm !== "" && (
                    <span className="input-group-append">
                        <div className="btn" onClick={clearSearchTerm}>
                            <FaXmark/>
                        </div>
                    </span>
                )}

            </div>

            {/*<InputField></InputField>*/}
        </>
    )
}

export default KioskSearch;
