import React from 'react';
import './FunctionButton.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

const FunctionButton = ({label, onButtonClick, buttonStyle = 'btn-primary', isDisabled = false, tooltipText = ""}) => {

    const tooltipId = Math.random().toString(36).substring(7);

    return (
        <>
            <div className="gcComponent input-submit-button">


                <Tooltip
                    anchorSelect="#my-anchor-element"
                    content="Hello world!"
                />

                <Tooltip id={tooltipId}>{tooltipText}</Tooltip>
                <div data-tooltip-id={tooltipId}>
                    <button
                        className={`btn ${buttonStyle}`}
                        onClick={onButtonClick}
                        disabled={isDisabled}
                    >{label}</button>
                </div>
            </div>
        </>
    )
}

export default FunctionButton;
