import React, {useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {setResponseCheckedOut } from "@/app/reducers/responseSlice.js";

import Cookies from "js-cookie";
import axios from "axios";
import {formatTime, getLocaleTimeZone, maskEmail, maskName} from "@/app/Utilities/helpers.js";

import './CheckoutForm.css';

import Avatar from "@/app/components/Avatar/Avatar.jsx";
import SubmitButton from "@/app/Utilities/SubmitButton/SubmitButton.jsx";

import {ModalContext} from "@/app/context/ModalContext.jsx";
import { HiMail } from "react-icons/hi";
import {getValidToken} from "@/app/API/KioskApi.js";

const CheckoutForm = ({response, callClose}) => {

    const apiUrl = useSelector(selectApiUrl);
    const dispatch = useDispatch();

    const [buttonLabel, setButtonLabel] = useState("Check out now");
    const [checkoutState, setCheckoutState] = useState(false);
    const [followUpSent, setFollowUpSent] = useState(false);
    const [checkoutButtonClass, setCheckoutButtonClass] = useState("btn-checked-in");
    const [checkoutComplete, setCheckoutComplete] = useState(false);
    const [countDown, setCountDown] = useState(0);

    const modalContext = useContext(ModalContext);

    const startCountDown = (seconds) => {
        setCountDown(seconds);
        const interval = setInterval(() => {
            setCountDown(countDown => countDown - 1);
        }, 1000);

        const timeout = setTimeout(() => {
            clearInterval(interval);
            callClose();
        }, seconds * 1000);

        modalContext.registerTimeout(timeout);
    }

    const postCheckout = async (response) => {

        try {
            const token = await getValidToken();

            const postdata = {
                response: response,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const checkoutResult = await axios.post(apiUrl + '/checkout',
                postdata,
                config
            );

            setCheckoutState(true);
            setCheckoutButtonClass("btn-primary");

            dispatch(setResponseCheckedOut({
                id: response.id,
                checkedIn: false,
                checkedOut: true,
                todaysHour: checkoutResult.data.data.todaysHour
            }));

            const newModalData = {
                modalTitle: "You checked out at " + formatTime(checkoutResult.data.data.todaysHour[0].hour_date_end),
                modalSubtitle: ""
            }

            modalContext.updateModalData(newModalData);
            modalContext.updateResetToHome(true);

            setCheckoutComplete(true);

            let countDownLimit = 5;
            if(checkoutResult.data.data.followUpSent){
                setFollowUpSent(true);
                countDownLimit = 10;
            }

            setButtonLabel("Close");
            startCountDown(countDownLimit);

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {

        if (isSubmitting){
            return;
        }
        setIsSubmitting(true);

        if(checkoutState){
            callClose();
            setIsSubmitting(false);
        }else{
            postCheckout(response).finally(() => setIsSubmitting(false));
        }
    }

    const getCheckoutCompleteMessage = () => {

        if(followUpSent){
            return (
                <>
                    <div className="alert alert-light checkout-alert">
                        <div className={"row"}>
                            <div className={"col-2"}>
                            <span className={"checkout-complete-alert-icon round-border-icon"}>
                                 <HiMail/>
                            </span>
                            </div>
                            <div className={"col-10"}>
                                <div>Check your email</div>
                            </div>
                        </div>
                        <div className={"row pt-3"}>
                            <div className={"col-12"}>
                                <div className={"text-center"}>
                                    We've sent you a few questions to answer about your volunteer experience today.
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div className="text-center">You have successfully checked out.</div>
            )
        }
    }

    return (
        <>
            {checkoutComplete ? (
                getCheckoutCompleteMessage()
            ) : (
                <div className="row">
                    <div className="col-md-2">
                        <Avatar user={response.user}></Avatar>
                    </div>
                    <div className="col-md-10">
                        <div className="text-title">{maskName(response.user.user_fname, response.user.user_lname)}</div>
                        <div>{maskEmail(response.user.user_email)}</div>
                    </div>
                </div>
            )}
            {countDown > 0 &&
                <div className="pt-3 text-center">This window will close in {countDown} seconds</div>
            }

            <SubmitButton
                label={buttonLabel}
                onSubmitClick={handleSubmit}
                buttonClass={checkoutButtonClass}
            />
        </>
    )
}

export default CheckoutForm;

