import { createSlice } from '@reduxjs/toolkit'

// console.log(import.meta.env);

const apiUrl = document.getElementById('app').getAttribute("data-api_url");
const kioskUrl = document.getElementById('app').getAttribute("data-app_url");
const imageBucket = document.getElementById('app').getAttribute("data-image_bucket");

// let apiUrl = 'https://api.galaxydigital.com/api';
// if(import.meta.env.VITE_API_URL){
//     apiUrl = import.meta.env.VITE_API_URL;
// }
//
// let kioskUrl = 'https://checkin.gcvol.com';
// if(import.meta.env.VITE_APP_URL){
//     kioskUrl = import.meta.env.VITE_APP_URL;
// }
//
// let imageBucket = 'https://s3.amazonaws.com/files.galaxydigital.com';
// if(import.meta.env.VITE_IMAGE_BUCKET){
//     imageBucket = import.meta.env.VITE_IMAGE_BUCKET;
// }

export const kioskSlice = createSlice({
    name: 'kiosk',
    initialState: {
        apiKey: '',
        apiUrl: apiUrl + '/kiosk',
        kioskUrl: kioskUrl,
        imageBucket: imageBucket,
        kioskHash: '',
        managerMode: false,
        settings: null,
        welcomeMessage: ''
    },
    reducers: {
        setDomainSettings: (state, action) => {
           state.settings = action.payload;
        },
        setKioskHash: (state, action) => {
            state.kioskHash = action.payload
        },
        setKioskWelcomeMessage: (state, action) => {
            state.welcomeMessage = action.payload;
        },
        setManagerMode: (state, action) => {
            state.managerMode = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setDomainSettings,
    setKioskHash,
    setManagerMode,
    setKioskWelcomeMessage,

} = kioskSlice.actions

// The function below is a selector and allows us to select a value from the state.
export const selectSettings = (state, name) => {
    if(!state.kiosk.settings){
        return null;
    }

    if(state.kiosk.settings[name]){
        return state.kiosk.settings[name];
    }else{
        return null;
    }
};
export const selectApiKey = (state) => state.kiosk.apiKey;
export const selectManagerMode = (state) => state.kiosk.managerMode;
export const selectApiUrl = (state) => state.kiosk.apiUrl;
export const selectKioskUrl = (state) => state.kiosk.kioskUrl;
export const selectImageBucket = (state) => state.kiosk.imageBucket;
export const selectKioskWelcomeMessage = (state) => state.kiosk.welcomeMessage;
export const selectKioskHash = (state) => state.kiosk.kioskHash;

export default kioskSlice.reducer
