export const maskEmail = (email) => {

    const atIndex = email.indexOf('@');

    if (atIndex < 4) {
        const start = email.substring(0, atIndex - 1);
        const masked = '*';
        const end = email.substring(atIndex);
        return start + masked + end;
    }

    const start = email.substring(0, 3);
    const end = email.substring(atIndex);
    const masked = '*'.repeat(atIndex - 4);
    return start + masked + end;
}

export const maskName = (fname, lname) => {
    const masked = fname + " " + lname.charAt(0);

    return masked;
}

export const formatTime = (time) => {
    const date = new Date(time);

    let options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const timeString = date.toLocaleTimeString('en-US', options);

    let formattedTime = timeString.replace(" AM", "am").replace(" PM","pm");

    return formattedTime;
}

export const getLocalIsoString = () => {
    const date = new Date();
    const isoString = date.toLocaleString();
    return isoString;
};

export const getLocaleTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const shiftString = (shift) => {

    if(shift.need.need_date_type === 'shifts' || shift.need.need_date_type === 'reoccurring'){
        return formatTime(shift.start) + " - " + formatTime(shift.end)
    }else{
        return "";
    }

}

export const truncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}

export const __ = (string, replacements) => {

    if (replacements) {
        return string.replace(/%s/g, () => {
            return replacements.shift();
        });
    }

    return string;
}

// Retrieve JSON string from local storage
const storedLanguageOverridesJSON = localStorage.getItem('languageOverrides');
const storedLanguageOverrides = JSON.parse(storedLanguageOverridesJSON);

export const lang = (key) => {

    let value = key;

    if (storedLanguageOverrides) {
        const originalKey = key;
        const originalWords = key.trim().split(/\s+/);

        const lowerCaseKey = key.toLowerCase();
        const upperCaseKey = originalWords.map((word, index) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');


        // get default value
        if (storedLanguageOverrides[originalKey]) {
            value = storedLanguageOverrides[originalKey];
        }

        // update if key is in lowercase
        if (storedLanguageOverrides[lowerCaseKey]) {
            value = storedLanguageOverrides[lowerCaseKey];
        }

        if (storedLanguageOverrides[upperCaseKey]) {
            value = storedLanguageOverrides[upperCaseKey];
        }

        // Match the case of the original key
        if (originalKey.charAt(0) === originalKey.charAt(0).toUpperCase()) {
            const valueWords = value.trim().split(/\s+/);

            value = valueWords.map((word, index) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');

        } else {
            value = value.charAt(0).toLowerCase() + value.slice(1);
        }
    }

    return value;
};

export const getResponseCount = (responses) => {
    let responseCount = responses.length;

    const teamsMap = new Map();
    responses.forEach(response => {
        if (response.team) {
            teamsMap.set(response.team.id, response.team.reservedSlots);
        }
    });

    teamsMap.forEach((reservedSlots, teamId) => {
        responseCount += reservedSlots;
    });

    return responseCount;
}

export const checkFull = (slots, responseCount) => {

    if(isNaN(slots) || slots === ""){
        return false;
    }

    return slots <= responseCount;

};
