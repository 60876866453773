import {createContext, useState} from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({children, currentShift}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
        setResetCheckinModal(true);

        setIsModalOpen(false);
        setModalComponent("");
    };

    const [modalData, setModalData] = useState({
        'modalTitle': '',
        'modalSubTitle': '',
        'modalFeedback': ''
    });
    const updateModalData  = (newModalData) => {
        setModalData(newModalData);
    }

    const [hideButton, setHideButton] = useState(false);
    const updateHideButton = (bool) => {
        setHideButton(bool);
    }

    const [resetToHome, setResetToHome] = useState(false);
    const updateResetToHome = (bool) => {
        setResetToHome(bool);
    }

    const [modalComponent, setModalComponent] = useState("");
    const updateModalComponent = (componentKey) => {
        setModalComponent(componentKey);
    }

    const setModalOpen = (isOpen = true) => {
        setIsModalOpen(isOpen);
    }

    const [volunteer, setVolunteer] = useState({
        'user_fname': '',
        'user_lname': '',
        'user_email': ''
    });
    const updateVolunteer = (volunteer = false) => {
        if(volunteer === false){
            volunteer = {
                'user_fname': '',
                'user_lname': '',
                'user_email': ''
            }
        }

        setVolunteer(volunteer);
    }


    const [response, setResponse] = useState({});
    const updateResponse = (response) => {
        setResponse(response);
    }

    const [team, setTeam] = useState({});
    const updateTeam = (team) => {
        setTeam(team);
    }

    const [rootResponse, setRootResponse] = useState(null);
    const updateRootResponse = (response) => {
        setRootResponse(response);
    }

    const [autoCheckin, setAutoCheckin] = useState(false);
    const updateAutoCheckin = (autoCheckin) => {
        setAutoCheckin(autoCheckin);
    }

    const resetSignupComponent = () => {
        setVolunteer({
            'user_fname': '',
            'user_lname': '',
            'user_email': ''
        });

        setResponse({});
    }

    const [timeouts, setTimeouts] = useState([])
    const registerTimeout = (timeout) => {
        setTimeouts([...timeouts, timeout]);
    }

    const clearAllTimeouts = () => {

        timeouts.forEach(timeout => {
            clearTimeout(timeout);
        });
        setTimeouts([]);
    }

    const initialValue = {
        isModalOpen: isModalOpen,
        closeModal: closeModal,

        modalComponent: modalComponent,
        updateModalComponent: updateModalComponent,

        modalData: modalData,
        updateModalData: updateModalData,

        hideButton: hideButton,
        updateHideButton: updateHideButton,

        resetToHome: resetToHome,
        updateResetToHome: updateResetToHome,

        shift: currentShift,

        response: response,
        updateResponse: updateResponse,

        team: team,
        updateTeam: updateTeam,

        rootResponse: rootResponse,
        updateRootResponse: updateRootResponse,

        autoCheckin: autoCheckin,
        updateAutoCheckin: updateAutoCheckin,

        volunteer: volunteer,
        updateVolunteer: updateVolunteer,

        resetSignupComponent: resetSignupComponent,

        setModalOpen: setModalOpen,
        registerTimeout: registerTimeout,
        clearAllTimeouts: clearAllTimeouts,
    }

    return (
        <ModalContext.Provider value={initialValue}>
            {children}
        </ModalContext.Provider>
    );
}
