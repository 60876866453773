import './UserGroupSelect.css';
import {useContext, useEffect, useState} from "react";

import {selectApiUrl} from "@/app/reducers/kioskSlice.js";

import {AuthContext} from "@/app/context/AuthContext.jsx";

import { IoCloseSharp } from "react-icons/io5";
import Cookies from "js-cookie";
import axios from "axios";
import {useSelector} from "react-redux";
import {__, lang} from "@/app/Utilities/helpers.js";

const UserGroupSelect = ({userId, needId, updateSelected, checkedInState, isIvalid= true, setIsTouched}) => {

    const apiUrl = useSelector(selectApiUrl);

    const { checkToken } = useContext(AuthContext);

    const [selected, setSelected] = useState("");

    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const userGroupsSelected = userGroupOptions.filter(option => option.selected);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const token = await checkToken();

                if(token == false){
                    console.log("No token found");
                    return false;
                }

                const ajaxResponse = await axios.get(apiUrl + '/usergroups/volunteerUserGroups', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        userId: userId,
                        needId: needId
                    }
                });

                const userGroups = ajaxResponse.data.data;

                if(userGroups.length > 0){
                    userGroups.push({id: "na", ug_title: "N/A", selected: false});
                }

                setUserGroupOptions(userGroups);
                updateSelected(userGroups);

            } catch (error) {
                console.error('Error fetching user groups:', error);
            }
        };

        fetchData();
    }, []);

    const removeSelected = (removed) => {

        let updatedOptions = userGroupOptions.map((opt) => {
            if(opt.id === removed){
                opt.selected = false;
            }
            return opt;
        });

        // call parent update function if function is provided
        if(updateSelected){
            updateSelected(updatedOptions);
        }

        setUserGroupOptions(updatedOptions);

        setSelected("");
    };

    const handleSelectChange = (event) => {
        setIsTouched(true);

        let updatedOptions = userGroupOptions.map((opt) => {

            if(opt.id === event.target.value){
                opt.selected = true;
            }
            return opt;
        });

        // call parent update function if function is provided
        if(updateSelected){
            updateSelected(updatedOptions);
        }

        setUserGroupOptions(updatedOptions);

        setSelected("");

    };

    return (
        <>
            {(userGroupOptions.length > 0   && checkedInState != true) &&
                <div className={"userGroupSelectWrap"}>
                    <div className="col-md-12">
                        <div className="form-group">

                            <label htmlFor="userGroupSelect">{__("%s", [lang("User Group")])}</label>
                            <select
                                id="userGroupSelect"
                                value={selected}
                                className={(isIvalid) ? "form-control" : "form-control is-invalid"}
                                onChange={handleSelectChange}
                            >
                                <option value="">{__("Select %s", [lang("User Group")])}</option>
                                {userGroupOptions.map((userGroup) => (
                                    <option key={userGroup.id} value={userGroup.id} disabled={userGroup.selected}>
                                        {userGroup.ug_title}
                                    </option>
                                ))}
                            </select>

                            {!isIvalid &&
                                <div className={"invalid-feedback"}>
                                    User Group selection is required
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {userGroupsSelected.length > 0 &&
                <div>
                    <ul className={"selectedUserGroups"}>
                        {userGroupsSelected.map((userGroup, index) => (

                            <li key={userGroup.id}>
                            <span className={"pe-sm-1"}>
                                {userGroup.ug_title}
                                {checkedInState != true && (
                                    <a className="icon icon-delete btn ps-sm-1"
                                       onClick={() => removeSelected(userGroup.id)}>
                                        <IoCloseSharp/>
                                    </a>
                                )}
                            </span>
                            </li>

                        ))}
                    </ul>
                </div>
            }
        </>
    )
}

export default UserGroupSelect;
